import React, { useState } from 'react';
import AddTrip from './components/AddTrip';
import AddExpense from './components/AddExpense';
import ViewExpenses from './components/ViewExpenses';
import HomePage from './components/HomePage'; // 新增 HomePage 組件

function App() {
  const [currentPage, setCurrentPage] = useState('home');

  const renderPage = () => {
    switch (currentPage) {
      case 'addTrip':
        return <AddTrip />;
      case 'addExpense':
        return <AddExpense />;
      case 'viewRecords':
        return <ViewExpenses />;
      case 'home':
      default:
        return <HomePage />; // 渲染首頁顯示旅遊記錄
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-200 to-blue-300"> {/* 背景漸變 */}
      <header className="bg-blue-600 text-white p-6 shadow-md"> {/* 增加陰影和邊距 */}
        <h1 className="text-4xl font-bold tracking-wider text-center">記帳</h1> {/* 加大字體並設置字重 */}
      </header>

      <nav className="bg-white shadow-lg p-4 rounded-lg mx-4 mt-6">
        <ul className="flex justify-around space-x-4">
          <li>
            <button
              className={`text-lg font-semibold ${currentPage === 'home' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
              onClick={() => setCurrentPage('home')}
            >
              首頁
            </button>
          </li>
          <li>
            <button
              className={`text-lg font-semibold ${currentPage === 'addTrip' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
              onClick={() => setCurrentPage('addTrip')}
            >
              新增旅遊
            </button>
          </li>
          <li>
            <button
              className={`text-lg font-semibold ${currentPage === 'addExpense' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
              onClick={() => setCurrentPage('addExpense')}
            >
              新增消費
            </button>
          </li>
          <li>
            <button
              className={`text-lg font-semibold ${currentPage === 'viewRecords' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
              onClick={() => setCurrentPage('viewRecords')}
            >
              檢視消費記錄
            </button>
          </li>
        </ul>
      </nav>

      <main className="p-6">
        <div className="bg-white shadow-lg rounded-lg p-6">
          {renderPage()} {/* 主要內容區域 */}
        </div>
      </main>
    </div>
  );
}

export default App;