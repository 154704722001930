import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

function ViewExpenses() {
  const [expenses, setExpenses] = useState([]);
  const [trips, setTrips] = useState([]); // 儲存旅遊項目
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 用於切換編輯模式
  const [expenseData, setExpenseData] = useState(null); // 儲存當前編輯的消費數據

  const [searchQuery, setSearchQuery] = useState(''); // 搜尋關鍵字
  const [minPrice, setMinPrice] = useState(''); // 最小金額
  const [maxPrice, setMaxPrice] = useState(''); // 最大金額

  useEffect(() => {
    fetchAllExpenses(); // 初始加載所有消費記錄
    fetchTrips(); // 加載旅遊項目
  }, []);

  // 請求所有消費記錄，並按日期排序
  const fetchAllExpenses = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/expenses`);
      const sortedExpenses = response.data.sort((a, b) => new Date(b.date) - new Date(a.date)); // 按日期降序排序
      setExpenses(sortedExpenses);
    } catch (error) {
      console.error('無法加載消費記錄：', error);
    }
  };

  // 請求所有旅遊項目
  const fetchTrips = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/trips`);
      setTrips(response.data);
    } catch (error) {
      console.error('無法加載旅遊項目：', error);
    }
  };

  // 搜尋消費記錄
  const fetchSearchResults = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/expenses/search`, {
        params: { query: searchQuery, min_price: minPrice, max_price: maxPrice }
      });
      const sortedExpenses = response.data.sort((a, b) => new Date(b.date) - new Date(a.date)); // 按日期降序排序
      setExpenses(sortedExpenses);
    } catch (error) {
      console.error('搜尋失敗：', error);
    }
  };

  // 處理搜尋按鈕點擊
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery || minPrice || maxPrice) {
      fetchSearchResults(); // 如果有搜尋條件則搜尋
    } else {
      fetchAllExpenses(); // 沒有搜尋條件則加載所有記錄
    }
  };

  const showDetails = (expense) => {
    setSelectedExpense(expense);
    setExpenseData(expense); // 初始化編輯數據
    setIsEditing(false); // 關閉編輯模式
  };

  // 更新表單數據
  const handleChange = (e) => {
    setExpenseData({ ...expenseData, [e.target.name]: e.target.value });
  };

  // 更新商品明細數據
  const handleItemChange = (index, e) => {
    const newItems = [...expenseData.items];
    newItems[index][e.target.name] = e.target.value;
    setExpenseData({ ...expenseData, items: newItems });
  };

  const addItem = () => {
    setExpenseData({ ...expenseData, items: [...expenseData.items, { name: '', quantity: 1, amount: '' }] });
  };

  const removeItem = (index) => {
    const newItems = [...expenseData.items];
    newItems.splice(index, 1);
    setExpenseData({ ...expenseData, items: newItems });
  };

  const calculateTWD = () => {
    const totalYen = expenseData.items.reduce((sum, item) => sum + item.amount * item.quantity, 0);
    if (expenseData.exchange_rate) {
      return (totalYen * expenseData.exchange_rate).toFixed(0); // 計算並顯示台幣金額（四捨五入到整數）
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${API_BASE_URL}/api/expenses/${selectedExpense.id}`, expenseData);
      console.log('更新消費成功：', response.data);
      setIsEditing(false); // 完成編輯後返回詳細頁面
      setSelectedExpense(expenseData); // 更新顯示的詳細信息
    } catch (error) {
      console.error('更新消費失敗：', error);
    }
  };

  // 計算不含手續費和含手續費的匯率
  const calculateExchangeRate = () => {
    const totalYen = selectedExpense.items.reduce((sum, item) => sum + item.amount * item.quantity, 0);
    if (selectedExpense.actual_twd && selectedExpense.transaction_fee) {
      const withoutFee = selectedExpense.actual_twd / totalYen;
      const withFee = (selectedExpense.actual_twd + selectedExpense.transaction_fee) / totalYen;
      return { withoutFee, withFee };
    }
    return null;
  };

  // 顯示消費記錄總覽
  const renderOverview = () => {
    return (
      <div>
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">消費記錄總覽</h2>
  
        {/* 搜尋表單 */}
        <form onSubmit={handleSearch} className="mb-8 bg-white p-4 rounded-lg shadow-md">
          <label className="block mb-4">
            <span className="font-semibold text-gray-700">搜尋關鍵字：</span>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="輸入商品名稱、消費地點或備註"
            />
          </label>
          <label className="block mb-4">
            <span className="font-semibold text-gray-700">最低金額：</span>
            <input
              type="number"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="最低金額"
            />
          </label>
          <label className="block mb-4">
            <span className="font-semibold text-gray-700">最高金額：</span>
            <input
              type="number"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="最高金額"
            />
          </label>
          <button type="submit" className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow hover:bg-blue-600 transition duration-200">搜尋</button>
        </form>
  
        {/* 顯示消費記錄 */}
        {expenses.length === 0 ? (
          <p className="text-center text-gray-600">目前沒有消費記錄。</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full table-auto mb-4 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-300">
                  <th className="p-4 text-left font-semibold text-gray-700">日期</th>
                  <th className="p-4 text-left font-semibold text-gray-700">消費地點</th>
                  <th className="p-4 text-left font-semibold text-gray-700">總金額（日幣）</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((expense) => (
                  <tr
                    key={expense.id}
                    onClick={() => showDetails(expense)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="p-4">{expense.date}</td>
                    <td className="p-4">{expense.location}</td>
                    <td className="p-4">{Math.floor(expense.total)} 日幣</td> {/* 日幣不顯示小數點 */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  // 顯示消費詳細記錄
  const renderDetails = () => {
    if (!selectedExpense) return null;

    const exchangeRates = calculateExchangeRate();

    return (
      <div className="p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-4">詳細消費記錄</h3>
        <p className="text-gray-700">日期：{selectedExpense.date}</p>
        <p className="text-gray-700">地點：{selectedExpense.location}</p>
        <p className="text-gray-700">支付方式：{selectedExpense.payment_method}</p>
        <p className="text-gray-700">總金額（日幣）：{Math.floor(selectedExpense.total)} 日幣</p>

        {/* 顯示匯率相關數據 */}
        {exchangeRates && (
          <div>
            <p className="text-gray-700">
              匯率（不含手續費）：{exchangeRates.withoutFee.toFixed(3)}
            </p>
            <p className="text-gray-700">
              匯率（含手續費）：{exchangeRates.withFee.toFixed(3)}
            </p>
          </div>
        )}

        {selectedExpense.actual_twd && (
          <p className="text-gray-700">台幣金額：{selectedExpense.actual_twd}</p>
        )}
        <p className="text-gray-700">備註：{selectedExpense.note || '無'}</p>

        <h4 className="font-semibold text-lg mt-4 text-gray-800">商品明細</h4>
        <table className="w-full table-auto mb-4 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-300">
              <th className="p-4 text-left font-semibold text-gray-700">名稱</th>
              <th className="p-4 text-left font-semibold text-gray-700">數量</th>
              <th className="p-4 text-left font-semibold text-gray-700">金額（日幣）</th>
            </tr>
          </thead>
          <tbody>
            {selectedExpense.items.map((item, index) => (
              <tr key={index}>
                <td className="p-4">{item.name}</td>
                <td className="p-4">{item.quantity}</td>
                <td className="p-4">{Math.floor(item.amount)} 日幣</td> {/* 日幣不顯示小數點 */}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-between">
          <button onClick={() => setIsEditing(true)} className="bg-green-500 text-white py-2 px-4 rounded-lg">
            編輯
          </button>
          <button onClick={() => setSelectedExpense(null)} className="bg-blue-500 text-white py-2 px-4 rounded-lg">
            返回總覽
          </button>
        </div>
      </div>
    );
  };

  // 編輯消費記錄表單
  const renderEditForm = () => {
    return (
      <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg">
        <h3 className="text-lg font-bold mb-4">編輯消費記錄</h3>

        <label className="block mb-2">
          選擇旅遊項目：
          <select name="trip_id" value={expenseData.trip_id} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500">
            {trips.map(trip => (
              <option key={trip.id} value={trip.id}>
                {trip.title}
              </option>
            ))}
          </select>
        </label>

        <label className="block mb-2">
          日期：
          <input type="date" name="date" value={expenseData.date} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
        </label>

        <label className="block mb-2">
          消費地點：
          <input type="text" name="location" value={expenseData.location} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
        </label>

        <label className="block mb-2">
          支付方式：
          <select name="payment_method" value={expenseData.payment_method} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500">
            <option value="Cash">現金</option>
            <option value="Credit Card">信用卡</option>
            <option value="Other">其他</option>
          </select>
        </label>

        <h4 className="font-semibold mt-4 text-lg text-gray-800">商品明細</h4>
        <table className="w-full table-auto mb-4 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-300">
              <th className="p-4 text-left font-semibold text-gray-700">名稱</th>
              <th className="p-4 text-left font-semibold text-gray-700">數量</th>
              <th className="p-4 text-left font-semibold text-gray-700">金額（日幣）</th>
              <th className="p-4 text-left font-semibold text-gray-700">操作</th>
            </tr>
          </thead>
          <tbody>
            {expenseData.items.map((item, index) => (
              <tr key={index}>
                <td className="p-4">
                  <input type="text" name="name" value={item.name} onChange={(e) => handleItemChange(index, e)} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
                </td>
                <td className="p-4">
                  <input type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(index, e)} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
                </td>
                <td className="p-4">
                  <input type="number" name="amount" value={item.amount} onChange={(e) => handleItemChange(index, e)} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
                </td>
                <td className="p-4 text-center">
                  <button type="button" onClick={() => removeItem(index)} className="bg-red-500 text-white px-4 py-2 rounded-lg">刪除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={addItem} className="bg-green-500 text-white py-2 px-4 rounded-lg">新增商品</button>

        {expenseData.payment_method === 'Cash' && (
          <>
            <label className="block mt-4">
              當日匯率：
              <input
                type="number"
                name="exchange_rate"
                value={expenseData.exchange_rate}
                onChange={handleChange}
                className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
                step="0.001"
              />
            </label>
            <div className="mt-2">台幣金額：{calculateTWD()}</div>
          </>
        )}

        {expenseData.payment_method === 'Credit Card' && (
          <>
            <label className="block mt-4">
              實際台幣金額：
              <input type="number" name="actual_twd" value={expenseData.actual_twd} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
            </label>
            <label className="block">
              信用卡手續費：
              <input type="number" name="transaction_fee" value={expenseData.transaction_fee} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
            </label>
          </>
        )}

        <label className="block mt-4">
          備註：
          <textarea name="note" value={expenseData.note} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"></textarea>
        </label>

        <button type="submit" className="bg-blue-500 text-white py-2 px-4 mt-4 rounded-lg">提交</button>
        <button onClick={() => setIsEditing(false)} className="bg-gray-500 text-white py-2 px-4 mt-4 rounded-lg ml-4">取消</button>
      </form>
    );
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      {selectedExpense ? (isEditing ? renderEditForm() : renderDetails()) : renderOverview()}
    </div>
  );
}

export default ViewExpenses;