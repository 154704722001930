import React, { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';  // 導入 API 基礎 URL

function AddTrip() {
  const [tripData, setTripData] = useState({
    title: '',
    start_date: '',
    end_date: ''
  });

  const [error, setError] = useState(''); // 儲存錯誤訊息

  const handleChange = (e) => {
    setTripData({ ...tripData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 表單驗證
    if (!tripData.title || !tripData.start_date || !tripData.end_date) {
      setError('請填寫所有必填項目：標題、開始日期和結束日期。');
      return;
    }
    if (tripData.start_date > tripData.end_date) {
      setError('開始日期不能晚於結束日期。');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/trips`, tripData);  // 使用 API_BASE_URL
      console.log('新增旅遊成功：', response.data);

      // 新增成功後清空表單
      setTripData({
        title: '',
        start_date: '',
        end_date: ''
      });
      setError(''); // 清除錯誤訊息
    } catch (error) {
      console.error('新增旅遊失敗：', error);
      setError('新增旅遊失敗，請稍後再試。');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg space-y-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-700">新增旅遊項目</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>} {/* 顯示錯誤訊息 */}

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">標題：</span>
        <input
          type="text"
          name="title"
          value={tripData.title}
          onChange={handleChange}
          className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
        />
      </label>

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">開始日期：</span>
        <input
          type="date"
          name="start_date"
          value={tripData.start_date}
          onChange={handleChange}
          className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
        />
      </label>

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">結束日期：</span>
        <input
          type="date"
          name="end_date"
          value={tripData.end_date}
          onChange={handleChange}
          className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
        />
      </label>

      <button type="submit" className="bg-blue-500 text-white py-2 px-4 mt-4 rounded-lg hover:bg-blue-600 transition duration-200">
        新增旅遊
      </button>
    </form>
  );
}

export default AddTrip;