import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

function AddExpense() {
  const [trips, setTrips] = useState([]); // 儲存旅遊項目
  const [error, setError] = useState(''); // 儲存錯誤訊息
  const [expenseData, setExpenseData] = useState({
    trip_id: '',
    date: '',
    location: '',
    payment_method: 'Cash', // 預設為現金
    items: [{ name: '', quantity: 1, amount: '' }], // 初始的商品明細
    actual_twd: '',
    transaction_fee: '',
    exchange_rate: '', // 新增匯率欄位
    note: ''
  });

  useEffect(() => {
    // 加載所有旅遊項目
    axios.get(`${API_BASE_URL}/api/trips`).then(response => {
      setTrips(response.data);
    }).catch(error => {
      console.error('無法加載旅遊項目：', error);
    });
  }, []);

  const handleChange = (e) => {
    setExpenseData({ ...expenseData, [e.target.name]: e.target.value });
  };

  const handleItemChange = (index, e) => {
    const newItems = [...expenseData.items];
    newItems[index][e.target.name] = e.target.value;
    setExpenseData({ ...expenseData, items: newItems });
  };

  const addItem = () => {
    setExpenseData({ ...expenseData, items: [...expenseData.items, { name: '', quantity: 1, amount: '' }] });
  };

  const removeItem = (index) => {
    const newItems = [...expenseData.items];
    newItems.splice(index, 1); // 移除指定的商品
    setExpenseData({ ...expenseData, items: newItems });
  };

  const calculateTWD = () => {
    const totalYen = expenseData.items.reduce((sum, item) => sum + item.amount * item.quantity, 0);
    if (expenseData.exchange_rate) {
      return (totalYen * expenseData.exchange_rate).toFixed(0); // 計算並顯示台幣金額（四捨五入到整數）
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 表單驗證：檢查必要欄位是否填寫
    if (!expenseData.trip_id || !expenseData.date || !expenseData.location) {
      setError('請填寫所有必填項目：旅遊項目、日期和消費地點。');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/expenses`, expenseData);
      console.log('新增消費成功：', response.data);
      setError(''); // 清除錯誤訊息

      // 新增成功後清空表單
      setExpenseData({
        trip_id: '',
        date: '',
        location: '',
        payment_method: 'Cash',
        items: [{ name: '', quantity: 1, amount: '' }],
        actual_twd: '',
        transaction_fee: '',
        exchange_rate: '',
        note: ''
      });
    } catch (error) {
      console.error('新增消費失敗：', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg space-y-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-700">新增消費記錄</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>} {/* 顯示錯誤訊息 */}

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">選擇旅遊項目：</span>
        <select name="trip_id" value={expenseData.trip_id} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500">
          <option value="">請選擇</option>
          {trips.map(trip => (
            <option key={trip.id} value={trip.id}>{trip.title}</option>
          ))}
        </select>
      </label>

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">日期：</span>
        <input type="date" name="date" value={expenseData.date} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
      </label>

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">消費地點：</span>
        <input type="text" name="location" value={expenseData.location} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
      </label>

      <label className="block mb-2">
        <span className="font-semibold text-gray-700">支付方式：</span>
        <select name="payment_method" value={expenseData.payment_method} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500">
          <option value="Cash">現金</option>
          <option value="Credit Card">信用卡</option>
          <option value="Other">其他</option>
        </select>
      </label>

      <h3 className="text-xl font-bold mt-4">商品明細</h3>
      <table className="w-full table-auto mb-4">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 text-left">名稱</th>
            <th className="p-2 text-left">數量</th>
            <th className="p-2 text-left">金額（日幣）</th>
            <th className="p-2 text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          {expenseData.items.map((item, index) => (
            <tr key={index}>
              <td className="p-2">
                <input
                  type="text"
                  name="name"
                  value={item.name}
                  onChange={(e) => handleItemChange(index, e)}
                  className="border p-2 w-full rounded-lg"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  name="quantity"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, e)}
                  className="border p-2 w-full rounded-lg"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  name="amount"
                  value={item.amount}
                  onChange={(e) => handleItemChange(index, e)}
                  className="border p-2 w-full rounded-lg"
                />
              </td>
              <td className="p-2 text-center">
                <button type="button" onClick={() => removeItem(index)} className="bg-red-500 text-white px-4 py-2 rounded-lg">刪除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button type="button" onClick={addItem} className="bg-green-500 text-white py-2 px-4 rounded-lg">新增商品</button>

      {expenseData.payment_method === 'Cash' && (
        <>
          <label className="block mt-4">
            <span className="font-semibold text-gray-700">當日匯率：</span>
            <input
              type="number"
              name="exchange_rate"
              value={expenseData.exchange_rate}
              onChange={handleChange}
              className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"
              step="0.001" // 設定輸入的小數位數
            />
          </label>
          <div className="mt-2">
            <span className="font-semibold text-gray-700">台幣金額：</span> {calculateTWD()}
          </div>
        </>
      )}

      {expenseData.payment_method === 'Credit Card' && (
        <>
          <label className="block mt-4">
            <span className="font-semibold text-gray-700">實際台幣金額：</span>
            <input type="number" name="actual_twd" value={expenseData.actual_twd} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
          </label>
          <label className="block">
            <span className="font-semibold text-gray-700">信用卡手續費：</span>
            <input type="number" name="transaction_fee" value={expenseData.transaction_fee} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500" />
          </label>
        </>
      )}

      <label className="block mt-4">
        <span className="font-semibold text-gray-700">備註：</span>
        <textarea name="note" value={expenseData.note} onChange={handleChange} className="border p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-500"></textarea>
      </label>

      <button type="submit" className="bg-blue-500 text-white py-2 px-4 mt-4 rounded-lg">提交</button>
    </form>
  );
}

export default AddExpense;