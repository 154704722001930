import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

function HomePage() {
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    // 獲取所有旅遊項目
    axios.get(`${API_BASE_URL}/api/trips`)
      .then(response => {
        setTrips(response.data);
      })
      .catch(error => {
        console.error('無法加載旅遊項目：', error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 to-blue-200 p-6"> {/* 使用漸變背景 */}

      {/* 顯示每次旅遊的詳細信息 */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {trips.map(trip => (
          <div key={trip.id} className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl"> {/* 增加 hover 效果 */}
            <h2 className="text-2xl font-bold text-blue-600 mb-2">{trip.title}</h2>
            <p className="text-gray-700">開始日期：<span className="font-semibold">{trip.start_date}</span></p>
            <p className="text-gray-700">結束日期：<span className="font-semibold">{trip.end_date}</span></p>
            <p className="text-gray-900 mt-4 text-xl font-bold">
              總消費金額：<span className="text-red-500">{Math.floor(trip.total)} 日幣</span> {/* 無小數點 */}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;